import React from "react"
import { LocalizationContainer } from "./janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
// import localisation from "../../../images/janowo/mapa_poziom.jpg"
import {MobileButton} from "../../shared/button/button";

const Localization = ({ intl }) => (
  <LocalizationContainer className="localization">
    <Container className="localization-title">
      <Row>
        <Col xs="12" className="no-padding">
          <h1>{intl.formatMessage({ id: "offer.janowotwo.localization.title" })}</h1>
        </Col>
      </Row>
    </Container>
    <Container style={{ margin: "auto", padding: '0 30px' }}>

      <Row>
        <Col xs='12' className="no-padding">
          <div className="loc-map">
            <img src='/assets/images/janowo-2/mapa_OJP2_1920x1080.jpg' alt={intl.formatMessage({ id: "offer.janowotwo.localization.title" })} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="show-more" xs="12" style={{ padding: '40px 0px 0px', 'max-width': '300px', 'margin': 'auto' }}>
          <a target="_blank" href="https://www.google.pl/maps/place/Osiedle+Janowo+Park+-+WILLBUD/@54.5688655,18.4194632,1311m/data=!3m1!1e3!4m9!1m2!2m1!1sOsiedle+Janowo+Park+-+WILLBUD!3m5!1s0x0:0x204eccfcb2d6d2f3!8m2!3d54.5711949!4d18.4188711!15sCh1Pc2llZGxlIEphbm93byBQYXJrIC0gV0lMTEJVRFozChRvc2llZGxlIHBhcmsgd2lsbGJ1ZCIbb3NpZWRsZSBqYW5vd28gcGFyayB3aWxsYnVkkgETY29uZG9taW5pdW1fY29tcGxleA">
            <MobileButton
                innerText={intl.formatMessage({
                        id:
                            "offer.janowo.localization.info.google_button.text",
                      })}
                bg="#94C835"
                color="white"
                border="#94C835"
                arrow={false}
            />
          </a>
        </Col>
      </Row>
</Container>
    <Container className="localization-content">
      <Row>
        <Col xs="12" lg="3" className="no-padding places-title">
          <p>
            <span>
              {intl.formatMessage({
                id: "offer.janowo.localization.content.bold",
              })}
            </span>{" "}
            {intl.formatMessage({
              id: "offer.janowo.localization.content",
            })}
          </p>
        </Col>
        <Col xs="2" className="places-offset" />
        <Col xs="12" lg="7" className="no-padding places-info">
          <div>
            <Row>
              <Col sm="12" md="6" lg="4">
                <span>400m </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.first",
                })}
              </Col>
              <Col sm="12" md="6" lg="4">
                <span>1km </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.second",
                })}
              </Col>
              <Col sm="12" md="6" lg="4">
                <span>2km </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.third",
                })}
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col sm="12" md="12" lg="4">
                <span>2km </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.fourth",
                })}
              </Col>
              <Col sm="12" md="6" lg="4">
                <span>2km </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.fifth",
                })}
              </Col>
              <Col sm="12" md="12" lg="4">
                <span>7km </span>{intl.formatMessage({
                  id: "offer.janowo.localization.info.content.sixth",
                })}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  </LocalizationContainer>
)

export default injectIntl(Localization);
