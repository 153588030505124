import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import EstateVisualContainer from "@/components/estates/visual/estateVisualContainer"

import wiz1 from "@/images/janowo-2/model_OJP2_ujecie192-2.jpg"
import wiz2 from "@/images/janowo-2/wiz-janowo-4.jpg"
import wiz3 from "@/images/wiz5.jpg"
import wiz4 from "@/images/z-7/z-7j3.jpg"
import wiz5 from "@/images/z-7/z-7f.jpg"
import wiz6 from "@/images/z-7/wiz7.jpg"
// import wizZ8a from "@/images/z-8/wizZ8a.jpg"
import wiz9 from "@/images/z-9/Lazienka2_V03.jpg"
import wiz10 from "@/images/z-9/Lazienka3_V03.jpg"

const Visual = ({ intl }) => {
  const sections = [
    {
      title: intl.formatMessage({
        id: "offer.janowo.visual.title",
      }),
      items: [
        {
          title: "Dbamy o każdy szczegół",
          text: intl.formatMessage({
            id: "offer.janowotwo.visual.first.item.content",
          }),
          img: wiz5,
          imgClass: "cover",
        },
        {
          text: intl.formatMessage({
            id: "offer.janowotwo.visual.second.item.content",
          }),
          img: wiz2,
          imgClass: "cover",
        },
        // {
        //   // TODO move to translations when en translation available
        //   title: "Osiedle zaprojektowane z dbałością o najmniejszy szczegół",
        //   text: `Osiedle Janowo Park II to nie tylko wyjątkowa lokalizacja na granicy Rumi i Gdyni lecz również teren zaprojektowany z myślą o najwyższym komforcie użytkowania. Podczas tworzenia dla Państwa oferty uwzględniliśmy nasze wieloletnie doświadczenie oraz opinie naszych Klientów którzy zaufali nam podczas pierwszego etapu naszej inwestycji. Dzięki takiemu podejściu jesteśmy w stanie zaoferować Państwu osiedle najwyżej jakości.`,
        //   img: wiz5,
        //   divClass: "wiz-img_cut",
        //   imgClass: "top-0 cover",
        // },
        // {
        //   title: intl.formatMessage({
        //     id: "offer.janowotwo.visual.fourth.item.title",
        //   }),
        //   text: intl.formatMessage({
        //     id: "offer.janowotwo.visual.fourth.item.content",
        //   }),
        //   img: wiz4,
        //   imgClass: "cover",
        // },
        {
          // TODO move to translations when en translation available
          title: "Części wspólne wysokiej jakości",
          text: `Podczas projektowania inwestycji duży nacisk kładliśmy na <strong>estetykę</strong> oraz <strong>wysoką funkcjonalność</strong> części wspólnych.<br><br><strong>Kameralny budynek</strong> wchodzący w skład Osiedla Janowo Park II będzie wyposażony w <strong>cichobieżne windy</strong> we wszystkich klatkach rozpoczynające swój bieg od poziomu hali garażowej poprzez <strong>przestronne klatki schodowe</strong>.<br> Dodatkowym atutem jest duża ilość miejsc postojowych w hali garażowej, komórek lokatorskich oraz miejsc zewnętrznych. `,
          img: wiz6,
          imgClass: "cover",
        },
        {
          text: `<strong>Przytulna i klasyczna strefa wejścia</strong> utrzymana w stonowanej kolorystyce oraz przestronne korytarze z gustownymi portalami drzwiowymi tworzą elegancki styl.  `,
          img: wiz3,
          imgClass: "cover",
        },
      ],
    },
    {
      title: "Zmiany aranżacyjne i wykończenia pod klucz",
      items: [
        {
          text: `Wiemy, że wykończenie mieszkania często jest nie lada wyzwaniem, dlatego bazując na wieloletnim doświadczeniu naszych architektów i współpracy ze sprawdzonymi wykonawcami stworzyliśmy dział aranżacji i wykończeń pod klucz.<br><br>Jeśli potrzebujesz wprowadzić zmiany w układzie swojego mieszkania tak aby spełniało Twoje potrzeby, zaraz po zakupie lokalu, wspólnie z naszym architektem możesz dokonać niezbędnych poprawek.`,
          img: wiz9,
        },
        {
          text: `Możesz również skorzystać z pakietów wykończeniowych oszczędzając swój czas i pieniądze. To doskonałe rozwiązanie dzięki któremu zamieszkasz w pięknych i funkcjonalnych wnętrzach. Współpracując z naszym architektem stworzysz przestrzeń spełniającą Twoje oczekiwania.`,
          img: wiz10,
          imgStyle: { marginTop: "-42%" },
        },
      ],
    },
  ]

  return <EstateVisualContainer sections={sections} />
}
export default injectIntl(Visual)
